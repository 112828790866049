// Main js file in firialabs web dfu project
import './App.css'
import React, { useState } from 'react'
import DeviceTypeSelector from './DeviceTypeSelector'
import FirmwareLoader from './FirmwareLoader'
import DeviceFirmwareHelpPage from './DeviceFirmwareHelpPage'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import headerBackground from './assets/Logo-large-WHT.png'

// AppBar
const appBarStyles = makeStyles(theme => ({
  myImage: {
    height: '40px',
    marginRight: 10,
  },
  header: {
    backgroundColor: '#4EB748',
    position: 'relative',
    marginBottom: '1em',
  },
}))


function DenseAppBar(props) {
  const classes = appBarStyles()

  return (
    <AppBar className={classes.header}>
      <Toolbar>
        <img src={headerBackground} alt='header' className={classes.myImage} />
        <Typography variant='h6'> Firmware Updater</Typography>
      </Toolbar>
    </AppBar>
  )
} // End of AppBar

function App() {
  const [showHelp, setShowHelp] = useState(false)
  const [showInstructions, setShowInstructions] = useState(false)
  const [deviceIdState, setDeviceIdState] = useState('cb2')
  const [width, setWindowWidth] = useState(0)

  React.useEffect(() => {
    updateDimensions()

    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const onFirmwareClose = () => {
    setShowInstructions(false)
  }

  const onHelpClose = () => {
    setShowHelp(false)
  }

  const openHelp = () => {
    setShowHelp(true)
  }

  function deviceSelectedCallback(deviceId) {
    setDeviceIdState(deviceId)
    setShowInstructions(true)
  }

  function deviceHelpCb(deviceId) {
    setDeviceIdState(deviceId)
    setShowHelp(true)
  }

  return (
    <div className='FiriaLabs-app-main'>
      <DenseAppBar />
      <DeviceTypeSelector windowWidth={width} deviceSelected={deviceSelectedCallback} deviceFirmwareHelp={deviceHelpCb} />
      <FirmwareLoader open={showInstructions} deviceId={deviceIdState} onClose={onFirmwareClose} openHelp={openHelp} />
      <DeviceFirmwareHelpPage open={showHelp} deviceId={deviceIdState} onClose={onHelpClose} />
    </div>
  )
}

export default App
