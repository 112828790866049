/*
Collection of elements shared by FirmwareLoader and DeviceFirmwareHelpPage
*/
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import { Dialog } from '@material-ui/core'

export const firiaGreen = '#4EB748'

export const standardStyles = makeStyles(theme => ({
  button: {
    color: 'white',
    backgroundColor: firiaGreen,
    '&:hover': {
      backgroundColor: '#76D271', // Lighter shade of firiaGreen
    },
  },
}))

const cardStyles = makeStyles(theme => ({
  root: {
    width: 400,
    height: 350,
    //marginLeft: '30px',
    //marginTop: '10px',
    display: 'inline-flex',
    flexDirection: 'column',
    backgroundColor: '#F2F8F0',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  media: {
    height: 300,
    width: 400,
  },
}))

/* MediaCard is used to show each step relates to driver
installation process
*/
export function MediaCard(props) {
  const classes = cardStyles()

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={props.onClick}>
        <CardMedia
          className={classes.media}
          image={props.image}
          title={props.text}
        />
      </CardActionArea>
      <CardContent>
        <Typography gutterBottom variant='caption' component='h2'>
          {
            props.text
          }
        </Typography>
        <div style={{ position: 'absolute', right: `${props.index !== undefined ? 8 - props.index * 400 : 8}px`, bottom: '6px' }}>
          {props.actions}
        </div>
      </CardContent>
    </Card>
  )
}

export function FullScreenImage(props) {
  const standardClasses = standardStyles()

  if (props.tall !== undefined && props.tall) {
    return (
      <Dialog open={props.open} maxWidth={false}>
        <div style={{ height: '100%', width: 'calc(100vw - 64px)', maxWidth: 'calc(4/3 * calc(70vh - 64px))' }}>
          <div style={{ maxHeight: 'calc(3/4 * calc(100vw - 64px))', display: 'flex', alignItems: 'center', overflow: 'hidden' }}><img src={props.image} alt={''} style={{ width: '100%' }} /></div>
          <Button className={standardClasses.button} style={{ margin: '8px', position: 'relative', bottom: 0, left: '50%', transform: 'translateX(calc(-50% - 8px))' }} onClick={props.onClose}>
            CLOSE
          </Button>
        </div>
      </Dialog>
    )
  } else {
    return (
      <Dialog open={props.open} maxWidth={false}>
        <div style={{ height: '100%', width: 'calc(100vw - 64px)', maxWidth: '105vh' }}>
          <div style={{ maxHeight: 'calc(3/4 * calc(100vw - 64px))' }}><img src={props.image} alt={''} style={{ width: '100%' }} /></div>
          <Button className={standardClasses.button} style={{ margin: '8px', position: 'relative', bottom: 0, left: '50%', transform: 'translateX(calc(-50% - 8px))' }} onClick={props.onClose}>
            CLOSE
          </Button>
        </div>
      </Dialog>
    )
  }
}