/*
Web dfu backend functions live here
Downloaded from https://github.com/devanlai/webdfu
*/
import { dfu } from './dfu'
import { dfuse } from './dfuse'

export var device = null
export var myTestVar = 'Test Var'

let codebotVid = 0x0483
let codexVid = 0x303A

function hex4(n) {
  let s = n.toString(16)
  while (s.length < 4) {
    s = '0' + s
  }
  return s
}

function hexAddr8(n) {
  let s = n.toString(16)
  while (s.length < 8) {
    s = '0' + s
  }
  return '0x' + s
}

function niceSize(n) {
  const gigabyte = 1024 * 1024 * 1024
  const megabyte = 1024 * 1024
  const kilobyte = 1024
  if (n >= gigabyte) {
    return n / gigabyte + 'GiB'
  } else if (n >= megabyte) {
    return n / megabyte + 'MiB'
  } else if (n >= kilobyte) {
    return n / kilobyte + 'KiB'
  } else {
    return n + 'B'
  }
}

async function fixInterfaceNames(device_, interfaces) {
  // Check if any interface names were not read correctly
  if (interfaces.some(intf => (intf.name == null))) {
    // Manually retrieve the interface name string descriptors
    let tempDevice = new dfu.Device(device_, interfaces[0])
    await tempDevice.device_.open()
    await tempDevice.device_.selectConfiguration(1)
    let mapping = await tempDevice.readInterfaceNames()
    await tempDevice.close()

    for (let intf of interfaces) {
      if (intf.name === null) {
        let configIndex = intf.configuration.configurationValue
        let intfNumber = intf['interface'].interfaceNumber
        let alt = intf.alternate.alternateSetting
        intf.name = mapping[configIndex][intfNumber][alt]
      }
    }
  }
}

function getDFUDescriptorProperties(device) {
  // Attempt to read the DFU functional descriptor
  // TODO: read the selected configuration's descriptor
  return device.readConfigurationDescriptor(0).then(
    (data) => {
      let configDesc = dfu.parseConfigurationDescriptor(data)
      let funcDesc = null
      let configValue = device.settings.configuration.configurationValue
      if (configDesc.bConfigurationValue === configValue) {
        for (let desc of configDesc.descriptors) {
          if (desc.bDescriptorType === 0x21 && desc.hasOwnProperty('bcdDFUVersion')) {
            funcDesc = desc
            break
          }
        }
      }

      if (funcDesc) {
        return {
          WillDetach: ((funcDesc.bmAttributes & 0x08) !== 0),
          ManifestationTolerant: ((funcDesc.bmAttributes & 0x04) !== 0),
          CanUpload: ((funcDesc.bmAttributes & 0x02) !== 0),
          CanDnload: ((funcDesc.bmAttributes & 0x01) !== 0),
          TransferSize: funcDesc.wTransferSize,
          DetachTimeOut: funcDesc.wDetachTimeOut,
          DFUVersion: funcDesc.bcdDFUVersion,
        }
      } else {
        return {}
      }
    },
    (error) => { }
  )
}

// Current log div element to append to
let logContext = null

function logDebug(msg) {
}

function logInfo(msg) {
  if (logContext) {
    let info = document.createElement('p')
    info.className = 'info'
    info.textContent = msg
    logContext.appendChild(info)
  }
}

function logWarning(msg) {
  if (logContext) {
    let warning = document.createElement('p')
    warning.className = 'warning'
    warning.textContent = msg
    logContext.appendChild(warning)
  }
}

function logError(msg) {
  if (logContext) {
    let error = document.createElement('p')
    error.className = 'error'
    error.textContent = msg
    logContext.appendChild(error)
  }
}

function logProgress(done, total) {
  if (logContext) {
    let progressBar
    if (logContext.lastChild.tagName.toLowerCase() === 'progress') {
      progressBar = logContext.lastChild
    }
    if (!progressBar) {
      progressBar = document.createElement('progress')
      logContext.appendChild(progressBar)
    }
    progressBar.value = done
    if (typeof total !== 'undefined') {
      progressBar.max = total
    }
  }
}

function firiaDfu(props) {
  this.onDisconnect = (reason) => {
    device = null
    this.switch_back_to_main_page_cb(reason)
  }

  this.connect = async (device) => {
    try {
      await device.open()
    } catch (error) {
      this.onDisconnect(error)
      throw error
    }

    // Attempt to parse the DFU functional descriptor
    let desc = {}
    try {
      desc = await getDFUDescriptorProperties(device)
    } catch (error) {
      this.onDisconnect(error)
      throw error
    }

    let memorySummary = ''
    if (desc && Object.keys(desc).length > 0) {
      device.properties = desc
      if (desc.DFUVersion === 0x011a && device.settings.alternate.interfaceProtocol === 0x02) {
        device = new dfuse.Device(device.device_, device.settings)
        if (device.memoryInfo) {
          let totalSize = 0
          for (let segment of device.memoryInfo.segments) {
            totalSize += segment.end - segment.start
          }
          memorySummary = `Selected memory region: ${device.memoryInfo.name} (${niceSize(totalSize)})`
          for (let segment of device.memoryInfo.segments) {
            let properties = []
            if (segment.readable) {
              properties.push('readable')
            }
            if (segment.erasable) {
              properties.push('erasable')
            }
            if (segment.writable) {
              properties.push('writable')
            }
            let propertySummary = properties.join(', ')
            if (!propertySummary) {
              propertySummary = 'inaccessible'
            }
            memorySummary += `\n${hexAddr8(segment.start)}-${hexAddr8(segment.end - 1)} (${propertySummary})`
          }
        }
      }
    }
    // Bind logging methods
    device.logDebug = logDebug
    device.logInfo = logInfo
    device.logWarning = logWarning
    device.logError = logError
    device.logProgress = logProgress

    return device
  } // end of connect

  this.connectCallback = null
  this.connectFailure = null
  this.switch_back_to_main_page_cb = null
  this.deviceId = null


  this.connectButton = async (vid, pid, deviceId) => {
    const filters = [{ vendorId: vid, productId: pid }]

    this.deviceId = deviceId

    let attachedDevices, selectedUsbDevice
    try {
      attachedDevices = await navigator.usb.getDevices()
      selectedUsbDevice = await navigator.usb.requestDevice({
        filters: filters,
      })
    } catch (e) {
      this.connectFailure({ ...e, 'connecting': false })
      return
    }
    let interfaces = dfu.findDeviceDfuInterfaces(selectedUsbDevice)
    if (interfaces[0].alternate.interfaceProtocol === 0x01) {
      this.connectFailure({ 'connecting': true })
      await fixInterfaceNames(selectedUsbDevice, interfaces)
      device = await this.connect(new dfu.Device(selectedUsbDevice, interfaces[0]))
      // start detach
      if (device) {
        device.detach().then(
          async (len) => {
            try {
              await device.close()
              await device.waitDisconnected(5000)
            } catch (err) {
            }
          },
          async (error) => {
          }
        )
      }
      navigator.usb.addEventListener('connect', this.reconnectToSaved)
    } else {
      await fixInterfaceNames(selectedUsbDevice, interfaces)
      device = await this.connect(new dfu.Device(selectedUsbDevice, interfaces[0]))
      this.connectCallback(device)
    }
  }

  this.reconnectToSaved = async (ev) => {
    let interfaces = await dfu.findDeviceDfuInterfaces(ev.device)
    await fixInterfaceNames(ev.device, interfaces)
    device = await this.connect(new dfu.Device(ev.device, interfaces[0]))
    this.connectCallback(device)
    navigator.usb.removeEventListener('connect', this.reconnectToSaved)
  }

  let firmwareFile = null

  this.chooseFile = async (firmwareFileField) => {
    if (firmwareFileField.target.files.length > 0) {
      let reader = new FileReader()
      reader.onload = function () {
        firmwareFile = reader.result
      }
      reader.readAsArrayBuffer()
    }
  }

  this.massErase = async () => {
    try {
      let status = await device.getStatus()
      if (status.state === dfu.dfuERROR) {
        await device.clearStatus()
      }
    } catch (error) {
      device.logWarning('Failed to clear status')
    }

    // On STM32 a single-byte ERASE command invokes mass-erase
    await device.dfuseCommand(dfuse.ERASE_SECTOR, 0, 0)
  }

  this.flashFirmwareFile = async (filename) => {
    let file = null
    let doErase = true
    let transferSize
    let manifestationTolerant

    try {
      // console.log(`fetching file ${process.env.PUBLIC_URL + '/firmware/' + filename + '.bin'}`)
      let fetchUrl = process.env.PUBLIC_URL + '/firmware/' + filename + '.bin'
      file = await fetch(fetchUrl)
      doErase = false
      if (this.deviceId === 'cb2') {
        transferSize = 2048
        manifestationTolerant = false
      } else {
        transferSize = device.properties.TransferSize
        manifestationTolerant = device.properties.ManifestationTolerant
      }
    } catch (e) {
      // tell user about error
      console.error('flashFirmwareFile Error: ', e)
      return
    }

    firmwareFile = await file.arrayBuffer()

    if (device && firmwareFile != null) {
      try {
        let status = await device.getStatus()
        if (status.state === dfu.dfuERROR) {
          await device.clearStatus()
        }
      } catch (error) {
        device.logWarning('Failed to clear status')
      }
      await device.do_download(transferSize, firmwareFile, manifestationTolerant, doErase).then(
        () => {
          logInfo('Done!')
          if (!manifestationTolerant) {
            device.waitDisconnected(5000).then(
              (dev) => {
                device = null
              },
              (error) => {
                // It didn't reset and disconnect for some reason...
              }
            )
          }
        },
        (error) => {
          if (error !== 'Error during reset for manifestation: NetworkError: Unable to reset the device.') {
            this.onDisconnect()
          }
        }
      )
    }
  } // end of flashFirmwareFile
}

export const webDfu = new firiaDfu()