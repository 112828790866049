/*
This is used to show how to download device driver
for Windows computer. This page is only showing on Windows computer
*/
import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import FileSaver from 'file-saver'
import { Dialog } from '@material-ui/core'
import { MediaCard, standardStyles } from './SharedElements'
import { FullScreenImage } from './SharedElements'
import SwipeableViews from 'react-swipeable-views'

import { MobileStepper } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import { KeyboardArrowRight } from '@material-ui/icons'

import codebotInstallerStep0 from './assets/codebot-driver-help/installer-files1.jpg'
import codebotInstallerStep1 from './assets/codebot-driver-help/installer-step1.jpg'
import codebotInstallerStep2 from './assets/codebot-driver-help/installer-step2.jpg'
import codebotInstallerStep3 from './assets/codebot-driver-help/installer-step3.jpg'

import codexInstallerStep0 from './assets/codex-driver-help/installer-files1.jpg'
import codexInstallerStep1 from './assets/codex-driver-help/installer-step1.jpg'
import codexInstallerStep2 from './assets/codex-driver-help/installer-step2.jpg'
import codexInstallerStep3 from './assets/codex-driver-help/installer-step3.jpg'



const steps = {
  'cb2': [
    {
      image: codebotInstallerStep0,
      text: '1. Download, UnZip & Open Setup.exe',
      tall: false,
    },
    {
      image: codebotInstallerStep1,
      text: '2. Click Next',
      tall: true,
    },
    {
      image: codebotInstallerStep2,
      text: '3. Agree & Click Next',
      tall: true,
    },
    {
      image: codebotInstallerStep3,
      text: '4. Click Finish',
      tall: true,
    },
  ],
  'codex': [
    {
      image: codexInstallerStep0,
      text: '1. Download, UnZip & Open Setup.exe',
      tall: false,
    },
    {
      image: codexInstallerStep1,
      text: '2. Click Next',
      tall: true,
    },
    {
      image: codexInstallerStep2,
      text: '3. Agree & Click Next',
      tall: true,
    },
    {
      image: codexInstallerStep3,
      text: '4. Click Finish',
      tall: true,
    },
  ],
  'cb3': [
    {
      image: codexInstallerStep0,
      text: '1. Download, UnZip & Open Setup.exe',
      tall: false,
    },
    {
      image: codexInstallerStep1,
      text: '2. Click Next',
      tall: true,
    },
    {
      image: codexInstallerStep2,
      text: '3. Agree & Click Next',
      tall: true,
    },
    {
      image: codexInstallerStep3,
      text: '4. Click Finish',
      tall: true,
    },
  ],
}

function DeviceFirmwareHelpPage(props) {
  const standardClasses = standardStyles()

  const [showDoneButton, setShowDoneButton] = React.useState(false)
  const [fullScreen, setFullScreen] = React.useState(-1)
  const [fullScreenTall, setFullScreenTall] = React.useState(false)

  const [activeStep, setActiveStep] = React.useState(0)
  const [stepsHaveChanged, setStepsHaveChanged] = React.useState(false)


  // Full Screen Index: -1 => none, 0-9 => cb2 steps, 10-19 => cb3/codex steps
  const toggleFullScreen = (image) => {
    if (fullScreen === image) {
      setFullScreen(-1)
    } else {
      setFullScreen(image)
    }
  }

  useEffect(() => {
    setActiveStep(0)
    setShowDoneButton(false)
    setFullScreenTall(steps[props.deviceId][0].tall)
    setStepsHaveChanged(true)
    // TODO check these
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  const handleStepChange = (step) => {
    setActiveStep(step)
    if (step === steps[props.deviceId].length - 1) {
      setShowDoneButton(true)
    } else {
      setShowDoneButton(false)
    }
    setFullScreenTall(steps[props.deviceId][step].tall)
  }

  return (
    <div>
      <Dialog open={props.open} maxWidth={false}>
        <div >
          <Paper elevation={3} style={{ margin: '0px', marginLeft: 'auto', marginRight: 'auto', padding: '20px', width: '400px', Height: '900px' }} >
            <div style={{ margin: '0px', padding: '0px', minWidth: '400px' }}>
              <SwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {steps[props.deviceId].map((step, i) => {
                  if (i === 0) {
                    return (
                      < MediaCard key={i} index={i} image={step.image} text={step.text} actions={
                        <Button className={standardClasses.button}
                          onClick={() => {
                            if (props.deviceId === 'cb2') {
                              FileSaver.saveAs(process.env.PUBLIC_URL + '/codebot-installer.zip', 'codebot-cb2-driver')
                            } else {
                              FileSaver.saveAs(process.env.PUBLIC_URL + '/esp32-installer.zip', 'codex-and-cb3-driver')
                            }
                          }}
                        >
                          DOWNLOAD
                        </Button>
                      } onClick={() => {
                        toggleFullScreen(step.image)
                      }} />)
                  } else {
                    return (
                      < MediaCard key={i} image={step.image} text={step.text} onClick={() => {
                        toggleFullScreen(step.image)
                      }} />
                    )
                  }
                })}
              </SwipeableViews>
              <MobileStepper
                onMouseOver={() => {
                  if (stepsHaveChanged) {
                    const dots = document.getElementById('helpStepper').querySelectorAll('.MuiMobileStepper-dot')
                    dots.forEach((dot, i) => {
                      dot.addEventListener('click', (e) => {
                        handleStepChange(i)
                      })
                    })
                    setStepsHaveChanged(false)
                  }
                }}
                style={{ borderTop: '1px solid lightgrey' }}
                steps={steps[props.deviceId].length}
                position='static'
                activeStep={activeStep}
                nextButton={
                  <Button
                    size='medium'
                    onClick={() => {
                      if (activeStep >= steps[props.deviceId].length - 2) {
                        handleStepChange(steps[props.deviceId].length - 1)
                      } else {
                        const newStep = activeStep + 1
                        handleStepChange(newStep)
                      }
                    }}
                    disabled={activeStep === steps[props.deviceId].length - 1}
                  >
                    Next
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size='medium'
                    onClick={() => {
                      if (activeStep <= 1) {
                        handleStepChange(0)
                      } else {
                        const newStep = activeStep - 1
                        handleStepChange(newStep)
                      }
                    }}
                    disabled={activeStep === 0}
                  >
                    <KeyboardArrowLeft />
                    Back
                  </Button>
                }
                id='helpStepper'
              ></MobileStepper>
            </div>
            <div style={{ margin: 'auto', marginTop: '20px', textAlign: 'center' }}>

              <Button className={standardClasses.button}
                onClick={() => {
                  setShowDoneButton(false); props.onClose()
                }}
              >
                {showDoneButton ? 'DONE' : 'CANCEL'}
              </Button>
            </div>
          </Paper>
        </div>
      </Dialog >
      <FullScreenImage open={fullScreen !== -1} image={fullScreen} tall={fullScreenTall} onClose={() => {
        setFullScreen(-1)
      }} />
    </div >
  )
}

export default DeviceFirmwareHelpPage
