/*
Device type selector page,
When FiriaLabs introduces new educational product, we can add it here
*/
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import codebotImg from './assets/codebot/codebot.jpg'
import codexImg from './assets/codex/codex.jpg'
import codebotCB3Img from './assets/codebot/cb3.jpg'

export const deviceTypes = {
  cb2: {
    pid: 0xdf11,
    vid: 0x0483,
  },
  cb3: {
    pid: 0x0002,
    vid: 0x303A,
  },
  codex: {
    pid: 0x0002,
    vid: 0x303A,
  },
}

//Card
const cardStylesWide = makeStyles(theme => ({
  root: {
    maxWidth: 415,
    margin: '15px',
    //marginLeft: '30px',
    //marginTop: '30px',
    display: 'inline-flex',
    flexDirection: 'column',
  },

  media: {
    height: '22.5vw',
    maxHeight: '300px',
    //height: 300,
    //width: 400,
  },
  button: {
    color: 'white',
    backgroundColor: '#4EB748',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    '&:hover': {
      backgroundColor: '#76D271', // Lighter shade of firiaGreen
    },
  },
}))
const cardStylesNarrow = makeStyles(theme => ({
  root: {
    maxWidth: 415,
    margin: '15px',
    display: 'inline-flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  media: {
    height: '300px',
  },
  button: {
    color: 'white',
    backgroundColor: '#4EB748',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    '&:hover': {
      backgroundColor: '#76D271', // Lighter shade of firiaGreen
    },
  },
}))
const cardStylesVeryNarrow = makeStyles(theme => ({
  root: {
    maxWidth: 415,
    margin: '15px',
    display: 'inline-flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  media: {
    height: '64vw',
  },
  button: {
    color: 'white',
    backgroundColor: '#4EB748',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    '&:hover': {
      backgroundColor: '#76D271', // Lighter shade of firiaGreen
    },
  },
}))
//End of Card

function DeviceCard(props) {
  const classes = props.windowWidth < 750 ? props.windowWidth < 475 ? cardStylesVeryNarrow() : cardStylesNarrow() : cardStylesWide()

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={props.img}
        title={props.title + ' Bootloader Procedure'}
      />
      <CardContent>
        <Typography gutterBottom variant='h5' component='h2'>
          {
            props.title
          }
        </Typography>
        <Typography variant='body2' color='textSecondary' component='p'>
          {
            props.text
          }
        </Typography>
      </CardContent>
      <CardActions>
        <Button className={classes.button} size='large' color='primary'
          onClick={() => {
            props.deviceSelected(props.deviceId)
          }}  >
          UPGRADE
        </Button>
      </CardActions>
    </Card>
  )
}

const containerStylesNarrow = makeStyles(theme => ({
  container: {
    margin: '1em auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))
const containerStylesWide = makeStyles(theme => ({
  container: {
    margin: '1em 15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

function DeviceTypeSelector(props) {
  const classes = props.windowWidth < 750 ? containerStylesNarrow() : containerStylesWide()
  return (
    <div className={classes.container}>
      <DeviceCard
        windowWidth={props.windowWidth}
        deviceSelected={props.deviceSelected}
        deviceFirmwareHelp={props.deviceFirmwareHelp}
        deviceId={'cb2'}
        text={'Looking for new firmware for your CodeBot CB2? You\'ve come to the right place!'}
        textDriver={'CB2 upgrade driver'}
        title={'CodeBot CB2'}
        img={codebotImg}
        disableDriverSetup={true}
      />
      <DeviceCard
        windowWidth={props.windowWidth}
        deviceSelected={props.deviceSelected}
        deviceFirmwareHelp={props.deviceFirmwareHelp}
        deviceId={'cb3'}
        text={'Looking for new firmware for your CodeBot CB3? You\'ve come to the right place!'}
        textDriver={'CB3 upgrade driver'}
        title={'CodeBot CB3'}
        img={codebotCB3Img}
      />
      <DeviceCard
        windowWidth={props.windowWidth}
        deviceSelected={props.deviceSelected}
        deviceFirmwareHelp={props.deviceFirmwareHelp}
        deviceId={'codex'}
        text={'Looking for new firmware for your CodeX? You\'ve come to the right place!'}
        textDriver={'CodeX upgrade driver'}
        title={'CodeX'}
        img={codexImg}
      />
    </div>
  )
}

export default DeviceTypeSelector
