/*
Firmware loader UI which has step by step guide
*/

import React, { useState, useEffect, Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  LinearProgress,
  Box,
  Paper,
  Snackbar,
  Select,
  MenuItem,
  Typography,
  Dialog,
  Popover,
  MobileStepper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
} from '@material-ui/core'
import {
  History,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons'
import MuiAlert from '@material-ui/lab/Alert'
import SwipeableViews from 'react-swipeable-views'

import PropTypes from 'prop-types'

import {
  MediaCard,
  FullScreenImage,
  standardStyles,
} from './SharedElements'

import { webDfu } from './dfu-utils'
import FlyingCody from './FlyingCody'

//codebot images
import powerSwitch from './assets/codebot/power_switch.png'
import connectUsb from './assets/codebot/connect_usb.png'
import pressHold from './assets/codebot/button_0.png'
import pressRelease from './assets/codebot/reboot_button.png'
import releaseBtn from './assets/codebot/button_0_release.png'
import helpBtn from './assets/codebot/help.png'

//codex images
import codexConnectUsb from './assets/codex/codex_connect_usb.jpg'
import codexPressHold from './assets/codex/codex_button_up.jpg'
import codexPressRelease from './assets/codex/codex_reboot_button.jpg'
import codexReleaseBtn from './assets/codex/codex_button_up_release.jpg'
import codexHelpBtn from './assets/codex/codex-help.jpg'

import { deviceTypes } from './DeviceTypeSelector'

let espVid = 0x303A

let flashedDoneText = 'Update Complete! **Please RESET device**'

let connectingLoadingGlobal = 0

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

function DfuSnackbar(props) {
  const [open, setOpen] = React.useState(true)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar open={open} autoHideDuration={null} onClose={handleClose}>
      <Alert onClose={handleClose} severity={props.type} >
        {
          props.text
        }
      </Alert>
    </Snackbar>
  )
}

function LinearProgressWithLabel(props) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
  value: PropTypes.number.isRequired,
}

const progressStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: '0px',
  },
}))

function LinearWithValueLabel(props) {
  const classes = progressStyles()

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={props.progress} />
    </div>
  )
}

// Paper Styles
const paperStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(10),
      marginTop: '200px',
    },
    paddingTop: '0px',
    textAlign: 'center',
  },
}))


const flyingCodyStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: 'auto', //top horizontal bottom
  },
}))

// check the OS
function getOS() {
  let userAgent = window.navigator.userAgent
  let platform = window.navigator.platform
  let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  let iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  }
  return os
}

const steps = {
  'cb2': [
    {
      image: powerSwitch,
      text: '. Switch to USB Power',
      tall: true,
    },
    {
      image: connectUsb,
      text: '. Connect USB Cable',
      tall: true,
    },
    {
      image: pressHold,
      text: '. Press/Hold BTN-0',
      tall: true,
    },
    {
      image: pressRelease,
      text: '. While Holding BTN-0, Press/Release REBOOT',
      tall: true,
    },
    {
      image: releaseBtn,
      text: '. Release BTN-0',
      tall: true,
    },
    {
      image: helpBtn,
      text: '. Final Step',
      tall: true,
    },
  ],
  'codex': [
    {
      image: codexConnectUsb,
      text: '. Connect USB Cable',
      tall: false,
    },
    {
      image: codexPressHold,
      text: '. Press/Hold button "U"',
      tall: false,
    },
    {
      image: codexPressRelease,
      text: '. While Holding button "U," Press/Release RESET',
      tall: false,
    },
    {
      image: codexReleaseBtn,
      text: '. Release button "U"',
      tall: false,
    },
    {
      image: codexHelpBtn,
      text: '. Final Step',
      tall: true,
    },
  ],
  'cb3': [
    {
      image: powerSwitch,
      text: '. Switch to USB Power',
      tall: true,
    },
    {
      image: connectUsb,
      text: '. Connect USB Cable',
      tall: true,
    },
    {
      image: pressHold,
      text: '. Press/Hold BTN-0',
      tall: true,
    },
    {
      image: pressRelease,
      text: '. While Holding BTN-0, Press/Release REBOOT',
      tall: true,
    },
    {
      image: releaseBtn,
      text: '. Release BTN-0',
      tall: true,
    },
    {
      image: codexHelpBtn,
      text: '. Final Step',
      tall: true,
    },
  ],
}

let startTime = 0

let previousProgress = 0

let estimatedTotalTime = 0

function FirmwareLoader(props) {
  const flyingCodyClasses = flyingCodyStyles()
  const paperClasses = paperStyles()
  const standardClasses = standardStyles()

  const [progressState, updateProgressState] = useState(0)
  const [remainingTimeState, updateRemainingTimeState] = useState('')
  const [buttonDisplayedState, updateButtonDisplayedState] = useState('connect')
  const [versionsList, setVersionsList] = useState()
  const [selectedVersion, setSelectedVersion] = useState('')
  const [os] = useState(getOS())
  const [confirmEraseState, updateConfirmEraseState] = useState(false)

  const [activeStep, setActiveStep] = useState(0)
  const [stepsHaveChanged, setStepsHaveChanged] = useState(false)

  // from DisplayShown:
  const [fullScreen, setFullScreen] = React.useState(-1)
  const [fullScreenTall, setFullScreenTall] = React.useState(false)

  // Advanced options popover
  const [advancedAnchorEl, setAdvancedAnchorEl] = useState(null)

  // Help popup
  const [showHelpPopup, setShowHelpPopup] = useState(false)
  const [helpPopupConnecting, setHelpPopupConnecting] = useState(false)
  const [connectingLoading, setConnectingLoading] = useState(0)
  const [connectLoadingInterval, setConnectLoadingInterval] = useState(0)

  const makeVersionFilename = (deviceId, ver) => {
    const filename = `${deviceId}_${ver.date}_g${ver.sha}`
    return filename
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    async function fetchData() {
      try {
        const response = await fetch('versions.json', { signal })
        const text = await response.text()
        const vlist = JSON.parse(text)
        setVersionsList(vlist)
        const latest = vlist[props.deviceId].versions[0]  // Latest version is first in list
        const filename = makeVersionFilename(props.deviceId, latest)
        setSelectedVersion(filename)
      } catch (e) {
        if (e.name !== 'AbortError') {
          console.log('Error fetching versions file', e)
        }
      }
    }
    fetchData()

    return function cleanup() {
      // Cancel fetch() when component unmounts or is re-rendered
      controller.abort()
    }
  }, [props.deviceId])

  useEffect(() => {
    setActiveStep(0)
    setFullScreenTall(steps[props.deviceId][0].tall)
    setStepsHaveChanged(true)
    setShowHelpPopup(false)
    // TODO does this actually matter?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  function myLogProgress(done, total) {
    const progress = 100 * (done / total)
    const now = window.performance.now()
    if (progress > 5) {
      if (Math.round(progress) > previousProgress) {
        previousProgress = Math.round(progress) + 5
        estimatedTotalTime = Math.round((now - startTime) / (progress / 100))
      }
      const remainingTime = (estimatedTotalTime + startTime) - now
      let remainingTimeString = 'Estimated time remaining: '
      if (remainingTime >= 60000) { // at least one minute remaining
        remainingTimeString += Math.floor(remainingTime / 60000)
        remainingTimeString += ' minute'
        if (remainingTime >= 120000) { // more than one minute remaining
          remainingTimeString += 's'
        }
        remainingTimeString += ' '
      }
      remainingTimeString += Math.floor((remainingTime % 60000) / 1000)
      remainingTimeString += ' second'
      if ((remainingTime % 60000) >= 2000) { // more than one second remaining
        remainingTimeString += 's'
      }
      if (remainingTime < 1000) {
        remainingTimeString = 'Any time now!'
      }
      updateRemainingTimeState(remainingTimeString)
    }
    updateProgressState(progress)
    if (progress % 5 === 0) {
      console.log('Progress: ', progress)
    }
    if (progress === 100) {
      updateButtonDisplayedState('firmwareFlashed')
    }
  }

  async function connectedCallback(device) {
    startTime = window.performance.now()
    previousProgress = 0
    console.log('Connected!')
    setShowHelpPopup(false)
    updateProgressState(0)
    updateRemainingTimeState('')
    updateButtonDisplayedState('inprogress')
    device.logProgress = myLogProgress
    if (props.deviceId === 'cb2') {
      await webDfu.massErase()
    }
    await webDfu.flashFirmwareFile(selectedVersion)
  }

  function connectedFailure(reason) {
    if (reason.connecting) {
      setHelpPopupConnecting(true)
      clearInterval(connectLoadingInterval)
      setConnectLoadingInterval(setInterval(() => {
        if (connectingLoadingGlobal >= 3) {
          connectingLoadingGlobal = 0
        } else {
          connectingLoadingGlobal++
        }
        setConnectingLoading(connectingLoadingGlobal)
      }, 1000))
    } else {
      setHelpPopupConnecting(false)
      clearInterval(connectLoadingInterval)
    }
    setShowHelpPopup(true)
  }

  useEffect(() => {
    if (!showHelpPopup) {
      clearInterval(connectLoadingInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHelpPopup])

  function connectToDevice() {
    updateConfirmEraseState(false)
    webDfu.connectButton(deviceTypes[props.deviceId].vid, deviceTypes[props.deviceId].pid, props.deviceId)
  }

  function switch_back_to_main_page_cb(error, props) {
    if (buttonDisplayedState !== 'firmwareFlashed') {
      console.log('Disconnected!', error)
      updateButtonDisplayedState('disconnect')
    }
  }

  const handleChangeVersion = (ev) => {
    console.log('Version changed to ', ev.target.value)
    setSelectedVersion(ev.target.value)
  }

  const closeFirmware = () => {
    handleStepChange(0)
    props.onClose()
    updateButtonDisplayedState('connect')
    updateProgressState(0)
    updateRemainingTimeState('')
  }

  const handleConnectButton = () => {
    updateConfirmEraseState(true)
  }

  const closeConfirmErase = () => {
    updateConfirmEraseState(false)
  }

  webDfu.connectCallback = connectedCallback
  webDfu.connectFailure = connectedFailure
  webDfu.switch_back_to_main_page_cb = switch_back_to_main_page_cb

  // Need to tell user to reset the codex
  // When flashing is done
  if (deviceTypes[props.deviceId].vid === espVid) {
    flashedDoneText = 'Update Complete! **Please RESET device**'
  } else {
    flashedDoneText = 'Update Complete!'
  }

  // Full Screen Index: -1 => none, 0-9 => cb2 steps, 10-19 => codex steps, 11-19 => cb3 steps
  const toggleFullScreen = (image) => {
    if (fullScreen === image) {
      setFullScreen(-1)
    } else {
      setFullScreen(image)
    }
  }

  const versions = versionsList ? versionsList[props.deviceId].versions : []

  // Advanced options popover
  const showAdvanced = Boolean(advancedAnchorEl)

  const showAdvancedPopover = (event) => {
    setAdvancedAnchorEl(event.currentTarget)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
    setFullScreenTall(steps[props.deviceId][step].tall)
  }

  return (
    <div className={flyingCodyClasses.root}>
      {
        (buttonDisplayedState === 'connect') ? (
          <div className='FiriaLabs-app-main'>
            <div>
              <Dialog open={props.open} maxWidth={false}>
                <div >
                  <Paper elevation={3} style={{ margin: '0px', marginLeft: 'auto', marginRight: 'auto', padding: '20px', width: '400px', Height: '900px' }} >
                    {/* {os === 'Windows' ? (
                      <div style={{ maxWidth: '400px', textAlign: 'center', marginBottom: '20px' }}>
                        <Typography type='p'>First time upgrading <em style={{ textDecoration: 'underline' }}><strong>{props.deviceId === 'cb2' ? 'CodeBot CB2' : 'CodeX or CodeBot CB3'}</strong></em>?</Typography>
                        <Typography type='p'>Make sure to <button
                          style={{
                            color: '#0616d4',
                            backgroundColor: 'transparent',
                            border: 'none',
                            fontFamily: 'inherit',
                            fontSize: 'inherit',
                            padding: 0,
                            margin: 0,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={props.openHelp} >install</button> the driver!
                        </Typography>
                        <Typography type='p' style={{ fontSize: '0.75em' }}><em>Windows users only! No installation needed for Chromebook or Mac.</em></Typography>
                      </div>
                    ) : null} */}
                    <div style={{ margin: '0px', padding: '0px', minWidth: '400px' }}>
                      <SwipeableViews
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                      >
                        {steps[props.deviceId].map((step, i) => {
                          if (i === steps[props.deviceId].length - 1) {
                            return (
                              < MediaCard key={i} index={i} image={step.image} text={(i + 1) + step.text} actions={
                                <div>
                                  <Button onClick={showAdvancedPopover} style={{
                                    padding: '5px',
                                    minWidth: '0',
                                    marginRight: '12px',
                                  }}>
                                    <History />
                                  </Button>
                                  <Popover open={showAdvanced} anchorEl={advancedAnchorEl} onClose={() => {
                                    setAdvancedAnchorEl(null)
                                  }} anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }} transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}>
                                    <div style={{ padding: '8px' }}>
                                      <Select
                                        labelId='chver-label'
                                        id='chver-id'
                                        value={selectedVersion}
                                        onChange={handleChangeVersion}
                                      >
                                        {
                                          versions.map((ver, i) => {
                                            const fn = makeVersionFilename(props.deviceId, ver)
                                            return <MenuItem key={i} value={fn}>{fn + (i === 0 ? ' (latest)' : '')}</MenuItem>
                                          })
                                        }
                                      </Select>
                                    </div>
                                  </Popover>
                                  < Button className={standardClasses.button}
                                    onClick={handleConnectButton}
                                  >
                                    CONNECT
                                  </Button>
                                </div>
                              } onClick={() => {
                                toggleFullScreen(step.image)
                              }} />)
                          } else {
                            return (
                              < MediaCard key={i} image={step.image} text={(i + 1) + step.text} onClick={() => {
                                toggleFullScreen(step.image)
                              }} />
                            )
                          }
                        })}
                      </SwipeableViews>
                      <MobileStepper
                        onMouseOver={() => {
                          if (stepsHaveChanged) {
                            const dots = document.getElementById('stepStepper').querySelectorAll('.MuiMobileStepper-dot')
                            dots.forEach((dot, i) => {
                              dot.addEventListener('click', (e) => {
                                handleStepChange(i)
                              })
                            })
                            setStepsHaveChanged(false)
                          }
                        }}
                        style={{ borderTop: '1px solid lightgrey' }}
                        steps={steps[props.deviceId].length}
                        position='static'
                        activeStep={activeStep}
                        nextButton={
                          <Button
                            size='medium'
                            onClick={() => {
                              if (activeStep >= steps[props.deviceId].length - 2) {
                                handleStepChange(steps[props.deviceId].length - 1)
                              } else {
                                const newStep = activeStep + 1
                                handleStepChange(newStep)
                              }
                            }}
                            disabled={activeStep === steps[props.deviceId].length - 1}
                          >
                            Next
                            <KeyboardArrowRight />
                          </Button>
                        }
                        backButton={
                          <Button
                            size='medium'
                            onClick={() => {
                              if (activeStep <= 1) {
                                handleStepChange(0)
                              } else {
                                const newStep = activeStep - 1
                                handleStepChange(newStep)
                              }
                            }}
                            disabled={activeStep === 0}
                          >
                            <KeyboardArrowLeft />
                            Back
                          </Button>
                        }
                        id='stepStepper'
                      ></MobileStepper>
                    </div>

                    <div style={{ margin: 'auto', marginTop: '20px', textAlign: 'center' }}>
                      <Button className={standardClasses.button}
                        onClick={props.onClose}
                      >
                        CANCEL
                      </Button>

                    </div>
                  </Paper >
                  {
                    buttonDisplayedState === 'disconnect' ?
                      <div style={{ textAlign: 'center' }}>
                        <DfuSnackbar type={'error'} text={'Device Disconnected!'} />
                      </div> :
                      null
                  }
                </div >
              </Dialog >
              <FullScreenImage open={fullScreen !== -1} image={fullScreen} tall={fullScreenTall} onClose={() => {
                setFullScreen(-1)
              }} />
              <Dialog open={confirmEraseState}>
                <DialogTitle>
                  Are you sure you want to upgrade the firmware?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ color: 'black' }}>
                    Upgrading the firmware will <strong><span style={{ textDecoration: 'underline' }}>erase all files</span></strong> currently on the target device.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeConfirmErase}>
                    Cancel
                  </Button>
                  <Button onClick={connectToDevice} className={standardClasses.button} >
                    Upgrade
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={showHelpPopup}>
                <DialogTitle>
                  {helpPopupConnecting ? `Connecting to your device${connectingLoading === 1 ? '.' : connectingLoading === 2 ? '..' : connectingLoading === 3 ? '...' : ''}` : 'Trouble connecting to your device?'}
                </DialogTitle>
                <DialogContent>
                  {helpPopupConnecting ? <Typography type='p'>Device not connecting?</Typography> : null}
                  <Typography type='p'>Try the following:</Typography>
                  <ul>
                    <li>Unplug the device and go through all the steps again</li>
                    {os === 'Windows' ? (
                      <li>If this is your first time upgrading <span style={{ textDecoration: 'underline' }}>{props.deviceId === 'cb2' ? 'CodeBot CB2' : 'CodeX or CodeBot CB3'}</span>, you might need to <Link
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setShowHelpPopup(false); props.openHelp()
                        }} >
                        install
                      </Link> the <span style={{ textDecoration: 'underline' }}>{props.deviceId === 'cb2' ? 'CodeBot CB2' : 'CodeX or CodeBot CB3'}</span> driver.
                      <Typography type='p' style={{ fontSize: '0.75em' }}><em>Windows users only! No installation needed for Chromebook or Mac.</em></Typography>
                      </li>
                    ) : null}
                  </ul>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowHelpPopup(false)}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>

            </div>
            {/* <DisplayShown open={props.open} deviceId={props.deviceId} onClose={closeFirmware} /> */}
          </div>) : (
          <Dialog maxWidth={false} open={props.open} >
            <Fragment>
              <div style={{ width: '900px', maxWidth: '100%' }}>
                <div style={{ margin: '0px', marginLeft: 'auto', marginRight: 'auto', padding: '100px', maxWidth: '300px' }}>
                  <FlyingCody />
                </div>
                <div className={paperClasses.root} style={buttonDisplayedState === 'firmwareFlashed' || buttonDisplayedState === 'disconnect' ? { paddingLeft: '50px', paddingRight: '50px' } : { padding: '50px', paddingTop: '0' }}>
                  <div style={{ margin: '0px', marginLeft: 'auto', marginRight: 'auto', padding: '0px', maxWidth: '800px' }}>
                    <LinearWithValueLabel progress={progressState} />
                    <div>
                      {buttonDisplayedState === 'inprogress' ? '\n' + remainingTimeState : null}
                    </div>
                  </div>
                </div>
                {buttonDisplayedState === 'firmwareFlashed' ? <DfuSnackbar type={'success'} text={flashedDoneText} /> : buttonDisplayedState === 'disconnect' ? <DfuSnackbar type={'error'} text={'Device disconnected! Reset your device and try again.'} /> : null}
                {buttonDisplayedState === 'firmwareFlashed' || buttonDisplayedState === 'disconnect' ? (
                  < div style={{ textAlign: 'center', margin: '0px', marginLeft: 'auto', marginRight: 'auto', padding: '50px', maxWidth: '200px' }}>
                    <Button className={standardClasses.button}
                      onClick={closeFirmware}
                    >
                      {buttonDisplayedState === 'firmwareFlashed' ? 'DONE' : buttonDisplayedState === 'disconnect' ? 'CLOSE' : null}
                    </Button>
                  </div>
                ) : null}
              </div>
            </Fragment>
          </Dialog>)
      }
    </div >
  )
}

export default FirmwareLoader
